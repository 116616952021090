@tailwind base;
@tailwind components;
@tailwind utilities;

.typing {
  animation: blink-caret 0.75s step-end infinite;
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: white;
  }
}

@layer base {
  html {
    scroll-behavior: smooth;
  }
}

@layer components {
  .hover-white {
    @apply text-gray-400 border-2 border-gray-400 hover:border-white hover:text-white;
  }

  .group-hover-white {
    @apply text-gray-400 group-hover:text-white;
  }
}
